export const tableColumn = [
	{
		label: '序号',
		width: '120px',
		isCustomize: true,
		tableType: 'no',
	},
	{
		label: '客户名称',
		prop: 'customerName',
		width: '120px'

	},
	{
		label: '顾问名称',
		prop: 'nickname',
		width: '120px'
	},
	{
		label: '部门名称',
		prop: 'name',
		width: '120px'
	},
	{
		label: '提醒时间',
		prop: 'remindTime'
	},
	{
		label: '提醒内容',
		prop: 'content',
	},
	{
		label: '操作',
		isCustomize: true,
		prop: 'status',
		width: "360px",

	},
]
