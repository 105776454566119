<template>
    <FilterForm :formData="filterData" :filters="filters" @changeDept="changeDept" @submit="handleSubmit"
        @reset="handleReset" />
    <NotesTable v-if="tableData" :tableColumn="tableColumn" :tableData="tableData" @nameDrawer="handleNameDrawer"
        :page="pagination.page" :size="pagination.size" />
    <NotesPagination :total="total" :current-page="currentPage" @currentChange="currentChange"
        @sizeChange="sizeChange" />
</template>
<script>
import FilterForm from "@/components/filter";
import { onMounted, reactive, ref } from "vue";
import { tableColumn } from "./index.js";
import NotesTable from "@/components/table";
import NotesPagination from "@/components/pagination";
import { useStore } from "vuex";
import { getNotesList } from "@/api/method/my-notes";
import { reset } from "@/until";
import { getUserByDept } from "@/api/method/common";

export default {
    name: "ClientVisit",
    components: {
        FilterForm,
        NotesTable,
        NotesPagination,
    },
    setup() {

        const { dispatch, state } = useStore();

        onMounted(() => {
            getList();
            getDepartList();
        });

        const filterData = reactive({
            deptId: null,
            userId: null,
            search: null,
            date: null,
        });

        const filters = reactive([
            [
                {
                    label: "选择部门",
                    type: "select",
                    attr: "deptId",
                    placeholder: "请选择",
                    column: 8,
                    option: [],
                    labelWidth: "80px",
                },
                {
                    label: "选择顾问",
                    type: "select",
                    attr: "userId",
                    placeholder: "请选择",
                    column: 8,
                    option: [],
                    labelWidth: "100px",
                },
                {
                    type: "button",
                    column: 4,
                },
            ]
        ]);

        const getDepartList = async () => {
            await dispatch("departmentList");
            filters[0][0].option = state.departmentList.map((item) => ({
                label: item.fullName,
                val: item.id,
            }));
        };
        const pagination = reactive({
            page: 1,
            size: 10,
        });

        const total = ref(0)

        const tableData = ref([])
        const getList = async () => {
            const { date, ...param } = filterData;
            const startTime = date && date[0];
            const endTime = date && date[1];

            const res = await getNotesList({
                ...pagination,
                ...param,
                startTime,
                endTime,
            })
            console.log(res)
            if (res?.code == 200) {
                tableData.value = res.data.records;
                total.value = res.data.total;
            }
            // tableData.value= 
        };

        const currentPage = ref(1);
        const currentChange = (page) => {

            pagination.page = page;
            currentPage.value = page;
            getList();
        };
        const sizeChange = (size) => {
            pagination.size = size;
            currentPage.value = 1;
            getList();
        };

        const changeDept = async (id) => {
            const res = await getUserByDept(id);
            filters[0][1].option = res.data.map((item) => ({
                label: item.nickname,
                val: item.id,
            }));
        };

        const handleSubmit = () => {
            pagination.page = 1;
            currentPage.value = 1;
            getList();
        };

        const handleReset = () => {
            reset(filterData);
            getList();
        };


        return {
            filters,
            filterData,
            tableColumn,
            tableData,
            total,
            currentChange,
            sizeChange,
            pagination,
            currentPage,
            handleSubmit,
            handleReset,
            changeDept,
        };
    },
};
</script>

<style lang="scss" scoped>
.user-btn {
    padding-bottom: 40px;
}

.container {
    display: flex;
    min-height: 100%;

    .left {
        width: 250px;
        background: #f5f6f9;
        margin-right: 24px;
        padding: 20px;
    }

    .right {
        flex: 1;
        background-color: #fff;
        overflow: hidden;
    }
}
</style>

<style lang="scss">
.company-block {
    margin-bottom: 20px;
}

.company-block .el-input__inner {
    background: white !important;
}

.depart-tree {
    background: #f5f6f9;

    .el-tree-node__content {
        background: #f5f6f9;
    }
}
</style>